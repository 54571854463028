import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const IS_BOY_FIRST = true; //change to true for switch broadcast text

export const GIRL_NAME = 'Selvy Kuswanto';
export const GIRL_NAME_SHORT = 'Selvy';
export const GIRL_FATHER_NAME = `Dr. Anthio Kuswanto (Alm)`;
export const GIRL_MOTHER_NAME = `Lie Fa Tjhen`;
export const GIRL_PARENT_NAME =
  LANG === 'en'
    ? `The Daughter of Mr. ${GIRL_FATHER_NAME}, <br />And Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari Bapak ${GIRL_FATHER_NAME}, <br />Dan Ibu ${GIRL_MOTHER_NAME}`;

export const BOY_NAME = 'Michael Tanfebro';
export const BOY_NAME_SHORT = 'Michael';
export const BOY_FATHER_NAME = `N/A`;
export const BOY_MOTHER_NAME = `Inge Lauw`;
export const BOY_PARENT_NAME =
  LANG === 'en' ? `The Son of Mrs. ${BOY_MOTHER_NAME}` : `Putra dari Ibu ${BOY_MOTHER_NAME}`;

export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'mictanfebro';
export const IG_GIRL = 'selvykuswanto';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/adelinejune/michaelselvy/SEO.jpg?updatedAt=1698903501470`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1701576000;
export const EPOCH_RESEPSI_START = 1701603000;
export const EPOCH_END_EVENT = 1701612000;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-12-03');
export const DATE_RESEPSI = new Date('2023-12-03');

export const WEDDING_DATE_DATE = dayjs(DATE_AKAD).locale(LANG).format('DD MMMM YYYY');
export const WEDDING_AKAD_TIME = '11.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'JHL Solitaire Hotel – Gading Serpong';
export const WEDDING_AKAD_LOC_ROAD =
  'Jalan Gading Serpong Boulevard, Gading Serpong, Kec. Klp. Dua, Kabupaten Tangerang, Banten 15810';

export const WEDDING_RESEPSI_DAY = dayjs(DATE_RESEPSI).locale(LANG).format('dddd');
export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI).locale(LANG).format('DD MMMM YYYY');
export const WEDDING_RESEPSI_TIME = '18.30 WIB';
export const WEDDING_RESEPSI_LOC_NAME = WEDDING_AKAD_LOC_NAME;
export const WEDDING_RESEPSI_LOC_ROAD = `Jalan Gading Serpong Boulevard, Gading Serpong, Kec. Klp. Dua, Kabupaten Tangerang, Banten 15810`;
export const WEDDING_DATE = dayjs(DATE_RESEPSI).format('DD.MM.YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://michaelselvy.com/';
export const BROADCAST_WEDDING_LOCATION = `JHL Solitaire Hotel – Gading Serpong`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/TFWU5XQNeHby7QecA`;
export const GOOGLE_MAPS_ADDRESS = `JHL Solitaire Hotel – Gading Serpong`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Dave Moffatt (Shania Twain Cover) – You’re Still The One`;
export const SOUND_URL = 'https://www.youtube.com/watch?v=y4pETvy-2JI';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/tuS1LuPYQ_Q';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/tuS1LuPYQ_Q';

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbwu0Eqk0Pvee2DerYmNTL73DYRUfcth3e9YuhMhzEyQmjl0k_tPH778GO4njLUMxNClKg/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO =
  'https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';
